import React from "react"
import { Link, graphql } from "gatsby"
import {
  StyledBlogHeader,
  BlogPagination,
  PaginationPageNumber,
  PaginationList,
  CurrentPageNumber,
} from "../styledComponents/blogArchive"
import ShareTools from "../components/socialLinks"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import BlogList from "../components/BlogList"
import LayoutNoStripe from "../components/layoutNoStripe"
import SEO from "../components/seo"
import TagCloud from "../components/TagCloud"

const ResourceIndex = ({
  data,
  location,
  pageContext: { nextPagePath, previousPagePath, currentPage, numPages },
}) => {
  let posts = data.allWpPost.nodes

  const orderPostsByPinned = () =>
    posts.sort((a,b) => {
      var postA = a
      var postB = b
      if (postA.isSticky === true) {
        return new Date(postA.date) - new Date(postB.date);
      }
      if (postA === false) {
        return 1
      }

      //posts are the same
      return 0
    })


  posts = orderPostsByPinned()

  const breakpoints = useBreakpoint()

  if (!posts.length) {
    return (
      <LayoutNoStripe location={location}>
        <SEO title="All posts" />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </LayoutNoStripe>
    )
  }

  let pageTitleSEO
  let pageUrlSEO
  let pageDescriptionSEO = 'Articles about Quantum-Touch, Permissioning and Transformation™ and energy healing, with practical ways to improve your wellbeing, health and state of mind.'
  if(currentPage === 1){
    pageTitleSEO = 'Blog posts on health and wellbeing'
    pageUrlSEO = '/blog'
  }else {
    pageTitleSEO = 'Blog posts on health and wellbeing : page ' + currentPage + ' of ' + numPages
    pageUrlSEO = '/blog/' + currentPage
    pageDescriptionSEO = 'Archive page ' + currentPage + ' - Articles about Quantum-Touch, Permissioning and Transformation™ and energy healing, with practical ways to improve your wellbeing, health and state of mind.'
  }
  return (
    <LayoutNoStripe location={location} displayNavBorder>
      <SEO 
      title={pageTitleSEO} 
      description={pageDescriptionSEO}
      url={pageUrlSEO}
      />
      <StyledBlogHeader level={1} className="hidden" offScreen={true}>
        <h1>Blog</h1>
      </StyledBlogHeader>
      <BlogList posts={posts} />
      <Pagination>
        {previousPagePath ? (
          <Link to={previousPagePath} className="previousPageButton">
            <svg
              width="25"
              height="25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.536 8.35V7.128a.126.126 0 00-.204-.1l-7.137 5.575a.505.505 0 000 .796l7.137 5.575a.126.126 0 00.204-.1V17.65a.256.256 0 00-.096-.2l-5.7-4.448 5.7-4.45a.256.256 0 00.096-.2z" />
            </svg>
          </Link>
        ) : (
          <span className="previousPageButton">
            <svg
              width="25"
              height="25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.536 8.35V7.128a.126.126 0 00-.204-.1l-7.137 5.575a.505.505 0 000 .796l7.137 5.575a.126.126 0 00.204-.1V17.65a.256.256 0 00-.096-.2l-5.7-4.448 5.7-4.45a.256.256 0 00.096-.2z" />
            </svg>
          </span>
        )}

        {(breakpoints.xs || breakpoints.sm) && (
          <CurrentPageNumber isCurrentPage>{currentPage}</CurrentPageNumber>
        )}

        <PaginationList>
          {Array.from({ length: numPages }, (_, i) => (
            <PaginationPageNumber isCurrentPage={i === currentPage - 1} key={`pagination-number${i + 1}`}>
              <Link
                // key={`pagination-number${i + 1}`}
                to={`/blog/${i === 0 ? "" : i + 1}`}
              >
                {i + 1}
              </Link>
            </PaginationPageNumber>
          ))}
        </PaginationList>

        {nextPagePath ? (
          <Link to={nextPagePath} className="nextPageButton">
            <svg
              width="25"
              height="25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.536 8.35V7.128a.126.126 0 00-.204-.1l-7.137 5.575a.505.505 0 000 .796l7.137 5.575a.126.126 0 00.204-.1V17.65a.256.256 0 00-.096-.2l-5.7-4.448 5.7-4.45a.256.256 0 00.096-.2z" />
            </svg>
          </Link>
        ) : (
          <span className="nextPageButton">
            <svg
              width="25"
              height="25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.536 8.35V7.128a.126.126 0 00-.204-.1l-7.137 5.575a.505.505 0 000 .796l7.137 5.575a.126.126 0 00.204-.1V17.65a.256.256 0 00-.096-.2l-5.7-4.448 5.7-4.45a.256.256 0 00.096-.2z" />
            </svg>
          </span>
        )}
      </Pagination>
      <TagCloud />
    </LayoutNoStripe>
  )
}

export default ResourceIndex

const Pagination = ({ children }) => {
  return <BlogPagination>{children}</BlogPagination>
}

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!, $categories: [String!]) {
    allWpPost(
      filter: {
        categories: {
          nodes: {
            elemMatch: {
              slug: {
                in: $categories
              }
            }
          }
        }
      }
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        isSticky
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }
  }
`
